@import 'variables';
.option-button-wrap {
  padding: 1rem 0.5rem;
  .option-button {
    opacity: 1;
  }
  &.has-selection {
    opacity: 0.3;
    &.not-selected.incorrect {
      .option-button {
       transform: translate(0, 1rem);        
      }
    }
    &.not-selected.correct {
      opacity: 1;
      .option-button {
        border-color: $green;
      }
    }
  }
  &.has-selection.selected {
    opacity: 1;
  }
  &.selected.incorrect {
    opacity: 1;
    .option-button {
      background-color: $light-red;
      border-color: $red;
    }
  }
  &.selected.correct {
    .option-button {
      background-color: $light-green;
      border-color: $green;
    }
  }

}