@import "mixins";
@import "ease";

$black: #222222;
$white: #fefefe;
$blue: #6666ff;

$green: #2cff6d;
$light-green: #99ffaf;
$red: #ff2c2c;
$light-red: #f79c87;

$gray: #f3f3f3;

$light-blue: transparentize(blue, 0.9);