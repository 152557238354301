@import 'variables';

@import "reset";



@font-face {
  font-family: 'text';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Beirut Text/Beirut\ Text/BeirutText-Regular.ttf');
}

@font-face {
  font-family: 'text';
  font-weight: normal;
  font-style: italic;
  src: url('../assets/fonts/Beirut Text/Beirut\ Text/BeirutText-Italic.ttf');
}
@font-face {
  font-family: 'text';
  font-weight: bold;
  font-style: normal;
  src: url('../assets/fonts/Beirut Text/Beirut\ Text/BeirutText-Bold.ttf');
}
@font-face {
  font-family: 'text';
  font-weight: bold;
  font-style: italic;
  src: url('../assets/fonts/Beirut Text/Beirut\ Text/BeirutText-BoldItalic.ttf');
}
@font-face {
  font-family: 'title';
  font-weight: bold;
  src: url('../assets/fonts/Moderat Extended/Moderat Extended Exp/ModeratExtended-BoldExpanded.ttf');
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'text';
  * {
    box-sizing: content-box;
  }
}




html {
  font-size: 10px;
  @include desktop {
    font-size: 14px;
  }
}