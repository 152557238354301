
$min-screen: 400px;
$mobile-break: 811px;



@mixin mobile {
  @media (max-width: #{$mobile-break - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$mobile-break}) {
    @content;
  }
}
