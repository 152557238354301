@import 'variables';
.wrap {
  padding: 0rem 1rem;
  display: flex;

  align-items: center;
  justify-content: center;
  min-height: calc(100 * var(--vh, 1vh));
}
.score {
  // padding: 1rem;
  opacity: 1;
  font-size: 5rem;
  margin-bottom: 2rem;
  span {
    color: transparentize($black, 0.8);
  }
}
.waiting {
  text-align: center;
  padding: 1rem;
  .game-options {
    font-size: 2rem;
    margin-bottom: 2rem;
    label {
      margin-right: 1rem;
      padding-left: 1rem;
    }
    input {
      font-size: 2rem;
      border-left: 2px solid $gray;
      padding: 1rem;
      max-width: 3rem;
    }
  }
  .game-option {
    border: 2px solid $gray;
    display: flex;
    min-width: 30rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
    &:last-child {
      border-bottom: 2px solid $gray;

    }
  }
}

.game-round {
  opacity: 1;
  text-align: center;

  .round {
    opacity: 1;
    // font-size: 4rem;
    text-transform: uppercase;
    font-family: "title";
    margin-bottom: 1rem;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
  }
  .question {
    font-size: 3rem;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 5rem;
    max-width: 64rem;
    @include desktop {
    font-size: 5rem;

    }

    span {
      background: linear-gradient(
        to right,
        transparentize($blue, 0.9),
        transparentize($blue, 0.9)
      );
      background-size: 100% 10%;
      background-repeat: no-repeat;
      background-position: center 90%;
    }
  }
  .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 82rem;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
  }
}
