@import 'variables';

.button {
  padding: 2rem 2rem;
  border: 2px solid $gray;
  border-radius: 5px;
  font-family: 'title';
  font-size: 2rem;
  transition: 500ms $easeOutExpo all;
  background: $white;
   &:hover {
     box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.05);
     transform: translate(0, -2px);
     border-color: $black;
   }
}