
html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-kerning: normal;
  background: $white;
  color: $black;
}


html,
body {
  margin: 0;
  padding: 0;
  * {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
  }
}

hr {
  margin: 0.5rem 0;
  padding: 0;
  border: none;
  border-bottom: 2px solid $black;
}

button,
input,
textarea ,
select {
  outline: none;
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
  -webkit-appearance: none;
  border-radius: 0;
  font-family: 'title';
  color: inherit;
}
button {
  cursor: pointer;
}

svg {
  // fill: currentColor;
  vertical-align: middle;
}

a,
a:hover,
a:visited {
  color: $black;
}
canvas {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
